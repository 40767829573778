import React from 'react';
import MainPage from './components/MainPage';
import OverlaysMap from './components/OverlaysMap';
import { SspContextProvider, TranslationProvider } from './did-utility';
import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import Label from './did-utility/src/Components/Label';
import { getSetup } from './did-utility/src/Functions/getSetup';
import { logSession, STORAGE_KEY } from './did-utility/src/ActivityLogger/util';
import { OfflineLamp } from './did-utility/src/Components/OfflineLamp';
import * as packageJson from '../package.json';
import { ICON_HTML } from './components/icons';
import useServiceWorker from './did-utility/src/Functions/useServiceWorker';

declare module 'i18next' {
	interface CustomTypeOptions {
		returnNull: false;
	}
}

//used for version number in breakout screen
localStorage.setItem('version', packageJson.version);

i18next.use(initReactI18next).init({
	resources: {},
	lng: 'en-US',
	fallbackLng: 'en-US',
	returnNull: false,
	interpolation: {
		escapeValue: false,
	},
	saveMissing:
		(import.meta.env.VITE_REACT_APP_CHECK_KEYS as string) === 'true',
	missingKeyHandler: (lng, ns, key, fallbackValue) => {
		console.warn(lng, key, fallbackValue);
	},
});

var touchTimer: number | undefined;

function App() {
	const setup = getSetup();
	const projectVolkswagen = 'ced8f3b05e36e244eb48dabbaaa0259e';
	const projectAll = '515fa3c0991f95f7cfbc6cf76f5837ad';
	//nedc deprecated
	// const projectLabel =
	// 	!setup || setup.market?.nedc
	// 		? '7fae5acd09a8b5d738f42915295bd9d6'
	// 		: undefined;
	const projectSetup = '119c3a6b0142615995dac6bee43c8971';
	const params = new URLSearchParams(window.location.search);
	const showLabel = params.get('label') === 'true' || setup?.label;
	const apk = params.get('apk') === 'true';
	const setupLink = window.location.href.replace('vw/', '');

	useServiceWorker(getSetup()?.password || '', 'vw', [
		'/vw/index.html',
		'/vw/src/assets/fonts/VWText-Regular.otf',
		'/vw/src/assets/fonts/VWText-Bold.otf',
		'/vw/src/assets/fonts/VWHead.ttf',
		'/vw/src/assets/fonts/VWHead-Light.ttf',
		'/vw/src/assets/fonts/VWHead-Bold.ttf',
	]);

	const resetTimer = () => {
		if (touchTimer) {
			window.clearTimeout(touchTimer);
		}
		if (!sessionStorage.getItem(STORAGE_KEY)) {
			logSession();
		}
		touchTimer = window.setTimeout(() => {
			window.clearTimeout(touchTimer);
			touchTimer = undefined;
			logSession(Date.now() - 3 * 60000);
			window.dispatchEvent(new CustomEvent('reset'));
		}, 3 * 60000); // 3min
	};

	window.addEventListener('click', resetTimer);

	window.addEventListener('pause-timer', () => {
		if (touchTimer) window.clearTimeout(touchTimer);
	});

	window.addEventListener('restart-timer', resetTimer);

	if (params.get('apk') === 'true' && params.get('locked') === null) {
		params.set('locked', 'true');
		window.history.replaceState(null, '', '?' + params);
	}

	params.set('redirect', 'false');

	return (
		<SspContextProvider
			url={import.meta.env.VITE_REACT_APP_SSP_BASE_URL as string}
			vin={
				new URLSearchParams(window.location.search).get('vin') as string
			}
			setupLink={setupLink}
			prepareOfflineUse={!apk || (apk && !showLabel)}
			showLabel={showLabel}
		>
			<TranslationProvider
				projectBrand={projectVolkswagen}
				projectAll={projectAll}
				// projectLabel={projectLabel}
				projectSetup={projectSetup}
				setupLink={setupLink}
			>
				<div
					className='svgtemplates'
					dangerouslySetInnerHTML={{ __html: ICON_HTML }}
				/>
				{!showLabel && (
					<div
						className={`App${
							params.get('prefers-reduced-motion') === 'true' ||
							apk
								? ' prefers-reduced-motion'
								: ''
						}`}
						onTouchStart={resetTimer}
					>
						<MainPage />
						<OverlaysMap />
					</div>
				)}
				{showLabel && <Label brand={'vw'} apk={apk} />}
			</TranslationProvider>
			{!showLabel && <OfflineLamp />}
		</SspContextProvider>
	);
}

export default App;
