import React from 'react';
import { QRCodeSVG } from 'qrcode.react';
import i18next from 'i18next';
import { addOverlay, NavEnum, OverlayEnum } from '../../did-utility';
import { SET_NAV_TAB_ACTION } from '../../did-utility/src/SspProvider/store';
import SSPContentVO from 'sspcontentext/src/model/SSPContentVO';
import PriceInfo from '../PriceInfo';

// @ts-ignore
const Header = ({ headerContent, contentModel, store, headerRef }) => {
	const salesGroupModel = contentModel.ref('salesgroupref').asModel;
	const menuEnabled =
		['extColors', 'intColors', 'completeWheels', 'medialibrary'].some(
			ref => {
				const list = salesGroupModel.reflist(ref);
				return (
					list.some((item: SSPContentVO) =>
						item.asModel.bool('marketable', true)
					) ||
					(ref === 'medialibrary' && list.length > 0)
				);
			}
		) || salesGroupModel.has('accessories');

	const vehicleDataModel = contentModel.ref('vehicle_data').asModel;
	const hasWLTPData = vehicleDataModel
		.keys()
		.some((key: string | string[]) => key.includes('wltp'));

	const sibItems = contentModel.has('color_in_image')
		? contentModel
				.ref('color_in_image')
				.asModel.reflist('packageItems')
				.filter(
					(item: SSPContentVO) => item.asModel.str('family') === 'SIB'
				)
		: [];

	const padTypeValue =
		sibItems.length > 0
			? sibItems[0].asModel.target.layertitle[i18next.language] ||
				sibItems[0].asModel.str('layertitle')
			: null;

	const colors = [
		{ name: 'ColorOut', key: 'color_out' },
		{ name: 'ColorSeat', key: 'color_seat' },
		{ name: 'PadType', value: padTypeValue },
		{ name: 'ColorArm', key: 'color_arm' },
		{ name: 'ColorCarpet', key: 'color_carpet' },
		{ name: 'ColorSky', key: 'color_sky' },
	].filter(
		color =>
			(color.key &&
				contentModel.has(color.key) &&
				contentModel.str(color.key).trim() !== '') ||
			color.value
	);

	const techEntries = contentModel.ref('engine').asModel.reflist('entries');
	const techDataEmpty = techEntries.length === 0 && !hasWLTPData;
	const equipmentEmpty =
		colors.length === 0 &&
		contentModel.reflist('serie_equip').length === 0 &&
		contentModel.reflist('special_equip').length === 0;

	return (
		<header
			ref={headerRef}
			className={`${store.state.navTab === NavEnum.Overview ? 'dark' : ''}${headerContent === null ? ' sticking' : ''}`}
		>
			<div className='header'>
				<div className='qr-wrapper'>
					<div className='left'>
						<div className='header-title'>
							<div>
								{contentModel.bool('sold') && (
									<div className='sold'>
										<h4 className='bold'>
											{i18next.t('Sold')}
										</h4>
									</div>
								)}
								<p className='car-condition big'>
									{i18next.t(
										store.state.content?.contentType ===
											'Pricesheet'
											? 'ConditionNewCar'
											: 'ConditionUsedCar'
									)}
								</p>
								<h1>
									{(contentModel.target['title'] &&
										contentModel.target['title'][
											i18next.language
										]) ||
										contentModel.str('title')}
								</h1>
								{contentModel.str('subtitle') !== '-' && (
									<h1 className='light'>
										{(contentModel.target['subtitle'] &&
											contentModel.target['subtitle'][
												i18next.language
											]) ||
											contentModel.str('subtitle')}
									</h1>
								)}
							</div>
						</div>
					</div>
					<div className='right'>
						{contentModel.has('weblink_dga') &&
							contentModel.str('weblink_dga').trim() !== '' && (
								<div className='btn-qr-overlay'>
									<div className='dga-code-container'>
										<QRCodeSVG
											value={contentModel.str(
												'weblink_dga'
											)}
											size={102}
											bgColor={'#ffffff'}
											fgColor={'#000000'}
											level={'H'}
											includeMargin={false}
										/>
									</div>
									<div
										className='qr-text-container'
										hidden={
											store.state.navTab !==
											NavEnum.Overview
										}
									>
										<p className='qr-text'>
											{i18next.t(
												'ContentToGoButtonTitle'
											)}
										</p>
									</div>
								</div>
							)}
					</div>
				</div>
				{headerContent === null && (
					<PriceInfo
						hidden={store.state.navTab !== NavEnum.Overview}
					/>
				)}
				<div className='header-nav-bar'>
					<div
						className={`header-nav-bar-element menu${
							store.state.navTab === NavEnum.Overview
								? ' active'
								: ''
						}`}
						onClick={() =>
							store.dispatch({
								type: SET_NAV_TAB_ACTION,
								value: NavEnum.Overview,
							})
						}
					>
						{i18next.t('TabOverview')}
					</div>
					{!equipmentEmpty && (
						<div
							className={`header-nav-bar-element${
								store.state.navTab === NavEnum.Equipment
									? ' active'
									: ''
							}`}
							onClick={() =>
								store.dispatch({
									type: SET_NAV_TAB_ACTION,
									value: NavEnum.Equipment,
								})
							}
						>
							{i18next.t('TabEquipment')}
						</div>
					)}
					{equipmentEmpty && (
						<div
							className={`header-nav-bar-element${
								equipmentEmpty ? ' empty' : ''
							}`}
						>
							{i18next.t('TabEquipment')}
						</div>
					)}
					{!techDataEmpty && (
						<div
							className={`header-nav-bar-element${
								store.state.navTab === NavEnum.TechData
									? ' active'
									: ''
							}`}
							onClick={() =>
								store.dispatch({
									type: SET_NAV_TAB_ACTION,
									value: NavEnum.TechData,
								})
							}
						>
							{i18next.t('TabTechnicalData')}
						</div>
					)}
					{techDataEmpty && (
						<div
							className={`header-nav-bar-element${
								techDataEmpty ? ' empty' : ''
							}`}
						>
							{i18next.t('TabTechnicalData')}
						</div>
					)}
					{contentModel.has('salesgroupref') && menuEnabled && (
						<div
							className='header-nav-bar-element menu-btn'
							onClick={() => addOverlay(store, OverlayEnum.Menu)}
						>
							{i18next.t('TabMoreInformationModel')}
							<svg className='icon-24'>
								<use xlinkHref='#menu' />
							</svg>
						</div>
					)}
				</div>
			</div>
		</header>
	);
};

export default Header;
